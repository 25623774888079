*, html{
  padding:0;
  margin:0;
}

.container{
  width:90%;
  max-width:700px;
  margin: 10px auto;
}

.grid{
  margin-top:10px;
  width:100%;
}

.cell {
  width:calc(calc(99% / 3) - 7px);
  padding-top:calc(calc(99% / 3) - 7px);
  position:relative;
  margin:2px;
  display: inline-block;
  border: 1px solid rgb(233, 231, 231);
  cursor: pointer;
}

.cell > img {
  max-width:100%;
  max-height:100%;
  display:block;
  top:0;
  left:0;
  bottom:0;
  right:0;
  position:absolute;
}

.loadingWheel{
  padding:20px;
}

.loadingWheel > .loader {
  border: 6px solid lightgray;
  border-radius: 50%;
  border-top: 6px solid darkgray;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin:0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*
p.cellText{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
}
*/

header{
  padding:10px;
}

h1 {
  text-align:center;
}

header > img{
  text-align:right;
  margin: 0 auto;
  display:block;
  width:125px;
  height:125px;
}

button{
  text-align:center;
  font-size:16px;
  padding:10px;
  margin-top:15px;
  width:100%;
  height:45px;
  border-radius:10px;
  border: 2px solid black;
  background-color: white;
  transition-duration: 0.2s;
  cursor:pointer;
  color:black;
}

button:hover{
  border: 2px solid white;
  background-color: black;
  color:white;
}

a{
  color:black;
}